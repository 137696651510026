<template>
  <fair-layout :fair-id="fair.id" v-if="fair">
    <template v-slot:banner>
      <banner
        v-if="fair"
        description="Administrar espacios"
        :title="fair.name"
        type="banner--admin"
        :options="{padding: '30px'}">
      </banner>
    </template>
    <template v-slot:content>
      <div class="pt-3 px-7">
        <div class="row">
          <div class="col-12 d-flex justify-end">
            <v-btn
              color="secondary"
              class="elevation-0 text-none"
              @click="openPlaceModal()"
              dark
            >
              Crear lugar
            </v-btn>
            <v-btn
              color="primary"
              class="white--text text-none elevation-0 ml-2"
              @click="back"
              dark
            >
              Regresar
            </v-btn>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-center">
            <div class="overflow-auto">
              <konva-stage ref="stage" :config="{ width: 1200, height: 678 }">
                <konva-layer ref="layer">
                  <konva-image :config="{ image: fairPlaneImg }"/>
                  <konva-group
                    @mouseenter="handleMouseEnter"
                    @mouseleave="handleMouseLeave"
                    v-for="place in places"
                    :key="place.id"
                    :config="{
                  x: Number(place.xCoordinate),
                  y: Number(place.yCoordinate),
                }"
                    @click="openPlaceModal(place)"
                    :draggable="true"
                    @dragend="saveNewCoordinates($event, place)"
                  >
                    <konva-image
                      :config="{
                    image: fairPinIconImg,
                    width: 40,
                    height: 40,
                    x: -20,
                    y: -40,
                  }"
                    />
                    <konva-rect
                      :config="{
                    x: Number(0 - 75),
                    y: Number(0),
                    width: 150,
                    height: 20,
                    stroke: place.color,
                    fill: '#fff',
                    strokeWidth: 1,
                  }"
                    />
                    <konva-text
                      :config="{
                    x: -100,
                    y: 0,
                    text: place.name,
                    fontSize: 13,
                    fontFamily: 'Roboto',
                    fill: '#555',
                    width: 200,
                    padding: 5,
                    align: 'center',
                  }"
                    />
                  </konva-group>
                </konva-layer>
              </konva-stage>
            </div>
          </div>
        </div>
        <place-modal ref="placeModal" @onClose="onClose"/>
      </div>
    </template>
  </fair-layout>
</template>
<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import sessionMixin from '@/mixins/sessionMixin'
import { FAIRS_URL, PLACE_URL } from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import PlaceModal from '@/views/private/places/components/PlaceModal'
import Banner from '@/components/banner/Banner'
import FairLayout from '../../../layouts/FairLayout'

export default {
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (sessionMixin.methods.isSuperAdmin(user.roleId)) {
      next()
    } else {
      next({ path: '/error-403' })
    }
  },
  components: {
    FairLayout,
    Banner,
    PlaceModal
  },
  async created () {
    try {
      this.showLoader()
      await Promise.all([this.getFair(), this.getPlaces()])
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  data () {
    return {
      fair: null,
      fairPinIconImg: null,
      fairPlaneImg: null,
      places: []
    }
  },
  methods: {
    back () {
      this.$router.push({
        name: 'fairs'
      })
    },
    async getFair () {
      const response = await this.get(FAIRS_URL + '/' + this.$route.params.id)
      this.fair = response.data
      const fairPlane = new Image()
      fairPlane.src =
        process.env.VUE_APP_API_IMAGES_URL + this.fair.planeImageUrl
      fairPlane.onload = () => {
        this.fairPlaneImg = fairPlane
      }
      const fairPinIconImg = new Image()
      fairPinIconImg.src =
        process.env.VUE_APP_API_IMAGES_URL + this.fair.pinIconUrl
      fairPinIconImg.onload = () => {
        this.fairPinIconImg = fairPinIconImg
      }
    },
    async getPlaces () {
      const response = await this.get(PLACE_URL + '/all', {
        params: { fairId: this.$route.params.id }
      })
      this.places = response.data
    },
    handleMouseEnter (e) {
      const stage = e.target.getStage()
      stage.container().style.cursor = 'pointer'
    },
    handleMouseLeave (e) {
      const stage = e.target.getStage()
      stage.container().style.cursor = 'default'
    },
    async onClose () {
      try {
        this.showLoader()
        await this.getPlaces()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    },
    openPlaceModal (item) {
      this.$refs.placeModal.open(item, this.$route.params.id)
    },
    async saveNewCoordinates (event, place) {
      place.xCoordinate = event.target.absolutePosition().x
      place.yCoordinate = event.target.absolutePosition().y
      this.showLoader()
      await this.put(PLACE_URL + '/' + place.id + '/coordinates', place)
      this.hideLoader()
    }
  },
  mixins: [crudServiceMixin, loaderMixin, notificationMixin],
  name: 'Places'
}
</script>
